<template>
  <div class="MyConsigneeInfo">
    <el-row>
        <el-col :span="24"   class="titbox">
            <el-col class="title" :span="8">收货地址</el-col>
            <el-col class="text-right  addBtn" :span="16" @click.native="addMyConsignee">
                 新增收货地址
            </el-col>
        </el-col>
    </el-row>
    <div class="item" v-if="consigneeList.length > 0">
        <el-col
        :span="24"
        class="itemBox"
        v-for="(item, index) in consigneeList"
        :key="index"
        >
        <div class="itemContent">
            <p><span class="TitLabel">收货人: </span> <span>{{item.name}} </span><span class="defaultIcon"  v-if="item.isDefault==1">默认</span></p>
            <p><span class="TitLabel">手机号: </span> <span>{{item.phone}}</span></p>
            <p><span class="TitLabel" >地址: </span> <span>{{item.address}}</span></p>
            <div class="btnBox">
                <span v-if="item.isDefault==0" @click="setDefaultMyConsignee(item)">设为默认</span>
                <span class="edit" @click="editMyConsignee(item)">编辑</span>
                <span class="del" @click="delMyConsignee(item.id)">删除</span>
            </div>
        </div>
        </el-col>
        <div class="clear"></div>
    </div>
    <el-dialog :title="titleMsg" :visible.sync="dialogFormVisible" width="24%">
        <el-form  
         :model="ruleForm"
          :rules="rules"
          ref="ruleForm">
            <el-form-item label="收货人"    prop="name">
                <el-input v-model="ruleForm.name"  ref="name" autocomplete="off"  placeholder="请输入收货人" autofocus></el-input>
            </el-form-item>
            <el-form-item label="手机号"   prop="mobile">
                <el-input v-model.number="ruleForm.mobile" ref="mobile" autocomplete="off"  placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="详细地址"  prop="address">
                <el-input type="textarea" v-model="ruleForm.address" ref="address" autocomplete="off"  placeholder="请输入详细地址"></el-input>
            </el-form-item>
            <el-form-item label="是否默认地址" prop="isDefaultAddress">
                <el-switch v-model="ruleForm.isDefaultAddress"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button  @click="saveFun('ruleForm')">保存收货地址</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import Qs from "qs";
import { GetConsigneeList ,AddConsignee,delConsignee,editConsignee} from "api/carToOrder.js";
export default {
  name: "MyConsigneeInfo",
  inject:['reload'],

  data() {
    //表单验证
    let validateName = (rule, value, callback) => {
      if (!value ) {
        callback(new Error("请输入收货人"));
      } else {
        callback();
      }
    };

      let validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }else{
          if (!Number.isInteger(value)) {
            callback(new Error("请输入数字值"));
          } else {
            if (!/^1[3456789]\d{9}$/.test(value)) {
              callback(new Error("请输入正确的手机号码!"));
            } else {
              callback();
            }
          }
      }
      
    };
    let validateAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入地址信息"));
      } else {
        callback();
      }
    };
    return {
        titleMsg:'',
        dialogFormVisible: false,//初始新增收货信息为不显示
        ruleForm: {
          name: '',
          mobile: '',
          address: '',
          isDefaultAddress:false  //状态 false 0：不默认 || true 1：默认
        },
        rules: {
        name:[{required:true,message:"收货人必填", trigger: "blur"},{ validator: validateName, trigger: "blur" }],
        mobile: [{required:true,message:"手机号必填", trigger: "blur"},{ validator: validateMobile, trigger: "blur" }],
        address: [{required:true,message:"地址必填", trigger: "blur"},{ validator: validateAddress, trigger: "blur" }],
      },
        //加载loading条
      loading: false,
      consigneeList: [],
    };
  },
  methods: {
    //   设置默认地址
    setDefaultMyConsignee(item){
         let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
         let memberStoreAddress={
                         'id': item.id,
                        'memberStoreId':memberStoreId,
                        'address':item.address,
                        'name':item.name,
                        'phone':item.phone,
                        'isDefault':1
                    }
                   this.editConsigneeFun(memberStoreAddress)
    },
    //   新增收货地址弹框
    addMyConsignee(){
           this.dialogFormVisible = true;
           this.titleMsg="新增收货地址"
        
    },
    // 编辑收货地址弹框
    editMyConsignee(item){
        this.dialogFormVisible = true;
        this.titleMsg="编辑收货地址"
        this.ruleForm.address=item.address
        this.ruleForm.name=item.name
        this.ruleForm.mobile=Number(item.phone),
        this.editId=item.id
        if(item.isDefault==1){
            this.ruleForm.isDefaultAddress=true
        }else{
            this.ruleForm.isDefaultAddress=false
        }
    },
    // 删除收货地址
    delMyConsignee(id){
        this.$confirm('确定删除该收货地址, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let ids={
                ids:id
            }
            delConsignee(ids).then(data=>{
                console.log(data)
                if(data.data.code===400){
                    this.$message.error(data.data.msg);
                    return
                }else{
                    this.$message({
                        message:"删除成功",
                        type: 'success'
                    })
                    this.reload();
                }
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })         
        })
    },

// 修改编辑地址信息
editConsigneeFun(memberStoreAddress){
    editConsignee(memberStoreAddress).then(data=>{
                        console.log(data)
                        this.dialogFormVisible = false;
                        if(data.data.code===400){
                            this.$message.error(data.data.msg);
                            this.ruleForm.name='';
                            this.$nextTick(() => {
                                this.$refs.name.focus()
                            },100)
                            return
                        }else{
                            this.$message({
                                message:'编辑地址成功',
                                type: 'success'
                            });
                            this.reload();
                        }
                    })
},
  // 新增编辑地址信息
  AddConsigneeFun(memberStoreAddress){
        AddConsignee(memberStoreAddress).then(data=>{
                        console.log(data)
                        this.dialogFormVisible = false;
                        if(data.data.code===400){
                            this.$message.error(data.data.msg);
                            this.ruleForm.name='';
                            this.$nextTick(() => {
                                this.$refs.name.focus()
                            },100)
                            return
                        }else{
                            this.$message({
                                message:"新增地址成功",
                                type: 'success'
                            });
                            this.reload();
                        }
                    })       
  },
                               
    // 保存修改
    saveFun(formName){
        this.$refs[formName].validate((valid) => {
            if (valid) {
                let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
                let isDefault=this.ruleForm.isDefaultAddress
                if(isDefault){
                    isDefault=1
                }else{
                    isDefault=0
                }
                if(this.titleMsg=="编辑收货地址"){
                     let memberStoreAddress={
                         'id': this.editId,
                        'memberStoreId':memberStoreId,
                        'address':this.ruleForm.address,
                        'name':this.ruleForm.name,
                        'phone':this.ruleForm.mobile,
                        'isDefault':isDefault
                    }
                   this.editConsigneeFun(memberStoreAddress)
                }else{
                    let memberStoreAddress={
                        'memberStoreId':memberStoreId,
                        'address':this.ruleForm.address,
                        'name':this.ruleForm.name,
                        'phone':this.ruleForm.mobile,
                        'isDefault':isDefault
                    }
                    this.AddConsigneeFun(memberStoreAddress)
                }
            } else {
                return false;
            }
      });
     },
    // 获取
    getConsigneeList() {
        GetConsigneeList().then(res=>{
                console.log(res.data.data.records)
            // 收货信息赋值
          this.consigneeList = res.data.data.records
        //   alert(this.consigneeList.length)
      })
    },
  },
  created() {
    this.getConsigneeList();
    
  },
  components: {
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
 .defaultIcon {
          background: #666;
          color: #fff;
          padding: 0 5px;
        }
.titbox{
        padding: 20px!important;
        border-bottom:none;
        .title{
            border-left: 2px solid @theme;
            font-size: 16px;
            line-height: 22px;
            padding-left: 13px;
            color:#333333;
            font-weight: 600;
        }
        .addBtn{
             color: #409fe0;
             font-size: 13px;
             cursor: pointer;
        }
    }
.el-col/deep/ {
    padding: 0px;
}
    /deep/.el-dialog__header {
          background: #ECECEC;
          padding: 5px 10px;
          .el-dialog__title{
              font-size: 14px;
              font-weight: bold;
          }
          .el-dialog__headerbtn{
                top:8px
          }
    }
     /deep/.el-input__inner{
        line-height: 33px;
        width: 50%;
    }
    /deep/.el-dialog__body{
        padding:22px 40px 0 40px;
    }
    /deep/.el-form-item__label{
        width: 100%;
        text-align: left;
        line-height: 25px;
    }
    /deep/.dialog-footer{
        text-align: left;
            padding:0px 20px 20px;
        .el-button{
            background: @theme;
            color: #fff;
            &:hover{
                background: @theme;
            color: #fff;
            }
        }
    }
.clear {
  clear: both;
}
.item {
  padding:10px 0;
  .itemBox {
    margin-top:10px;
    .itemContent {
      border: 1px solid #f0f0f0;
      padding:20px;
      position: relative;
      .btnBox {
        position:absolute;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        right:20px;
        top:20px;
        span:first-child {
          color: #409fe0;
          cursor: pointer;
        }
        span:nth-child(2) {
          color: #409fe0;
          margin: 0 5px;
          cursor: pointer;
        }
        span:nth-child(3) {
          color: #409fe0;
          margin: 0 5px;
          cursor: pointer;
        }
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        margin-top: 12px;
        .TitLabel {
              text-align: justify;text-align-last: justify;text-justify:distribute-all-lines; 
                width: 48px;
                padding-right: 10px;
                color: #ccc;
                display: inline-block;
               
        }
        span:nth-child(2) {
           color: #333;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
