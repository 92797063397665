// 购物车结算页接口
import axios  from 'utils/axios'
import Qs from 'qs'
//获取默认收货地址  /blade-user/memberstoreaddress/getDefault    GET
export const GetDefaultConsigneeList=(memberStoreAddress)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstoreaddress/getDefault';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:{memberStoreAddress:memberStoreAddress},
       })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 获取收货人信息列表   /blade-user/memberstoreaddress/list    get  传入memberStoreAddress
export const GetConsigneeList=(memberStoreAddress)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstoreaddress/list';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:{memberStoreAddress:memberStoreAddress},
       })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 增加收货人信息   /blade-user/memberstoreaddress/save  post    传入memberStoreAddress（    memberStoreId  ....）
export const AddConsignee=(memberStoreAddress)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstoreaddress/save';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:memberStoreAddress
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 删除收货地址 /blade-user/memberstoreaddress/remove    post
export const delConsignee=(ids)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstoreaddress/remove';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(ids)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 修改收货地址 /blade-user/memberstoreaddress/update
export const editConsignee=(memberStoreAddress)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/memberstoreaddress/update';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:memberStoreAddress
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 药豆商城 提交订单  /blade-order/pointsorder/submit
export const IntegralMallSubmit = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-order/pointsorder/submit';
        axios({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: url,
                method: "post",
                data: datas
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 药豆商城 药豆支付订单  /blade-order/pointsorder/pay

export const IntegralMallPay=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/pointsorder/pay';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// APP-药豆+人民币支付订单   /blade-order/pointsorder/moneyPay
export const IntegralMallMoneyPay=(parmes)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/pointsorder/moneyPay';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(parmes)
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 购物车提交订单接口   /blade-order/order/carToOrder    post
export const carToOrder=(ids)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/order/carToOrder';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(ids)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 首页弹窗领取优惠券  http://39.98.114.1:8000/api/blade-coupon/coupon/baseInfo/receiveCoupons
export const ReceiveCoupons=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url ='api/blade-coupon/coupon/baseInfo/receiveCoupons';
        axios({
            headers:{
                'Content-Type': 'application/json',
                // 'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:datas
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 购物车手动领取优惠券  /blade-coupon/coupon/baseInfo/receiveCoupon    couponId    get
export const GetCoupon=(couponId)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-coupon/coupon/baseInfo/receiveCoupon';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:{id:couponId},
          
       })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 根据商品id领取优惠券(id用逗号分隔)    GET
export const relativeCoupon=(productIdList)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-coupon/couponFront/receive-coupon-by-pids';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:{productIdList:productIdList},
          
       })
        // axios({
        //     headers:{
        //         'Content-Type':'application/x-www-form-urlencoded',
        //     },
        //     url:url,
        //     method:"get",
        //     data:Qs.stringify(productIdList)
        // })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 订单结算    提交订单  /blade-order/order/submit    post

export const carSumbit=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/order/submit';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:datas
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 订单支付  /blade-order/order/payOrder    post
export const payOrder=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url ='api/blade-order/order/payOrder';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(datas)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

